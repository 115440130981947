.button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin: 5px;
    width: fit-content;
  }

  .button:disabled {
    cursor: not-allowed;
  }

  .primary {
    background-color: rgba(29, 114, 243, 1);
    color: white;
  }
  
  .secondary {
    background-color: #6c757d;
    color: white;
  }
  
  .success {
    background-color: #28a745;
    color: white;
  }
  
  .danger {
    background-color: #dc3545;
    color: white;
  }
  
  .warning {
    background-color: #ffc107;
    color: black;
  }
  
  .info {
    background-color: #17a2b8;
    color: white;
  }
  
  .light {
    background-color: #f8f9fa;
    color: black;
  }
  
  .dark {
    background-color: #343a40;
    color: white;
  }
  
  .link {
    background: none;
    color: #1d72f3;
    text-decoration: underline;
  }
  
  .iconButton {
    display: flex;
    align-items: center;
  }
  
  .icon {
    margin-right: 8px;
  }