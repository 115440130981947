.coverPage {
    display: block;
    position: fixed; 
    top: 0;
    left: 0; 
    width: 100%;
    min-width: 100%;
    height: 100%; 
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    }
    
    .buttonContainer {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    
    .myStyleModalAll {
    align-items: flex-start;
    background-color: #3c3d4d;
    align-self: center;
    display: flex;
    width: 25%;
    height: 150px;
    min-width: 200px;
    min-height: 150px;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #ffffff;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    }
    
    .myStyleModalTitle {
      align-items: center;
      background-color: #3c3d4d;
      display: flex;
      flex-direction: column;
      color: #ffffff;
      width: 100%;
      font-size: 24px;
      }

    .myStyleModalText {
    align-items: center;
    background-color: #3c3d4d;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    width: 100%;
    font-size: 24px;
    }
    
    
    
    @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      
      .loader {
        border: 4px solid rgba(77, 76, 76, 0.984);
        border-left-color: #dcdcdc;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        margin-top: 7px;
        animation: spin 1s linear infinite;
      }
    
    .errorContainer {
      min-height: 30px; /* Adjust the height as needed */
      margin-top: 5px;
    }
    
    .errorContainer .errorMessage {
      display: block;
    }
    
    .placeholder {
      visibility: hidden; /* Hides the element, but keeps its space */
      height: 30px; /* Adjust this height to match the height of your error message */
    }