.row {
    display: flex;
    align-items: center;
}

.search input {
  align-self: center;
    min-width: 250px;
    padding: 5px;
    border: none;
    border-radius: 4px;
}


@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loader {
    border: 4px solid rgba(77, 76, 76, 0.984);
    border-left-color: #dcdcdc;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
  }