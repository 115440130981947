.myStyleBottom {
    align-Self: center;
    justify-content: center;
    display: flex;
    width: 100%;
    min-width: 200px;
    min-height: 20px;
    max-Width: 1500px;
    max-Height: 200px;
    padding-Left: 50px;
    padding-Right: 50px;
}

