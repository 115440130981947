/* body{
  margin: 0 10%;
} */

.myStyleAll {
    border-Radius: 8px;
    /* box-Shadow: 0px 24px 48px 0px rgba(181, 181, 181, 0.2), 0px 3px 6px 0px rgba(173, 173, 173, 0.1); */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1), inset 0 0 50px rgba(167, 167, 167, 0.131);
    background-Color: #2a3037;
    /* padding: 0 10%; */
    display: flex;
    overflow: hidden;
    flex-Direction: column;
    align-Items: stretch;
    background: linear-gradient(135deg, rgb(10, 7, 22) 10%, rgb(48, 50, 59) 100%);
}


/* Style the header with a grey background and some padding */
.header {
    overflow: hidden;
    background-color: #f1f1f1;
    padding: 20px 10px;
  }
  
  /* Style the header links */
  .header a {
    float: left;
    color: black;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    line-height: 25px;
    border-radius: 4px;
  }
  
  /* Style the logo link (notice that we set the same value of line-height and font-size to prevent the header to increase when the font gets bigger */
  .header a.logo {
    font-size: 25px;
    font-weight: bold;
  }
  
  /* Change the background color on mouse-over */
  .header a:hover {
    background-color: #ddd;
    color: black;
  }
  
  /* Style the active/current link*/
  .header a.active {
    background-color: dodgerblue;
    color: white;
  }
  
  /* Float the link section to the right */
  .header-right {
    float: right;
  }
  
  /* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
  @media screen and (max-width: 500px) {
    .header a {
      float: none;
      display: block;
      text-align: left;
    }
    .header-right {
      float: none;
    }
  }