.dashboard-login {
  background-color: #2148c0;
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 200px;
  max-Width: 3000px;
  min-height: 100vh;
  overflow-y: auto;
  
}

.dashboard-login .overlap-wrapper {
  overflow: hidden;
  width: 100%;
}

.dashboard-login .overlap {
  position: relative;
}

.dashboard-login .overlap-group {
  background-color: #264ec9;
  border-radius: 362px;
  height: 724px;
  position: absolute;
  left: -300px;
  top: 340px;
  width: 750px;
}

.dashboard-login .ellipse {
  background-color: #234bc5;
  border-radius: 286px;
  height: 572px;
  left: 76px;
  position: absolute;
  top: 76px;
  width: 572px;
}

.dashboard-login .div {
  background-color: #274ec7;
  border-radius: 219px;
  height: 438px;
  left: 143px;
  position: absolute;
  top: 143px;
  width: 438px;
}

.dashboard-login .form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 230px;
}
