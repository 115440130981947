.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-Color: #2a3037;
    padding: 10px 20px;
    color: #ffffff;
    background: linear-gradient(135deg, rgb(10, 7, 22) 10%, rgb(48, 50, 59) 100%);
}

.logo img {
    height: 40px;
}

.navLinks {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
}

.navLinks li a {
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
}

.search input {
    padding: 5px;
    border: none;
    border-radius: 4px;
}

.buttons {
    display: flex;
    gap: 10px;
}

.login {
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.signup {
    background-color: #ffcc00;
    border: none;
    color: #000000;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.user {
    border: none;
    color: #ffdd53;
    padding: 5px 10px;
    border-radius: 4px;
}