
.loginBtn {
  all: unset;
  box-sizing: border-box;
  height: 45px;
  left: 0;
  position: absolute;
  top: 150px;
  width: 300px;
}

.loginWrapper {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 4px 4px #0000004c;
  height: 45px;
  position: relative;
  width: 300px;
}

.loginWrapper:hover {
  background-color: #2f2f2f;
  border-radius: 4px;
  box-shadow: 0px 4px 4px #0000004c;
  height: 45px;
  position: relative;
  width: 300px;
}

.login {
  color: #2148c0;
  font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 16px;
  font-weight: 600;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  white-space: nowrap;
}
