.coverPage {
display: block;
position: fixed; 
top: 0;
left: 0; 
width: 100%;
min-width: 100%;
height: 100%; 
background-color: rgba(0, 0, 0, 0.5);
z-index: 1;
}

.myStyleModalAll {
align-items: flex-start;
background-color: #3c3d4d;
align-self: center;
display: flex;
flex-direction: column;
width: 50%;
height: 70%;
min-width: 400px;
min-height: 400px;
padding: 20px;
border: 1px solid #ffffff;
border-radius: 8px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
}

.myStyleModalText {
align-items: flex-start;
background-color: #3c3d4d;
display: flex;
flex-direction: column;
color: #ffffff;
width: 100%;
}

.Table {
    width: 100%;
}

.tableWidth {
    width: 100%;
}

.myStyleInputName {
width: 300px;
flex: 1;
margin-right: 15px;
}

.myStyleInputDescription {
width: 300px;
flex: 1;
margin-right: 15px;
}

.myStyleModalButtonPlace {
display: flex;
width: 100%;
min-width:1000px;
height: 50px;
max-height: 100%;
}

.project {
    width: 90%;
    padding: 5px;
    border: none;
    border-radius: 4px;
    padding-left: 20px;
    padding-right: 20px;
}

.tagWindow {
    width: 100%;
    height: 100%;
    overflow: auto;
}


@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loader {
    border: 4px solid rgba(77, 76, 76, 0.984);
    border-left-color: #dcdcdc;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin-top: 7px;
    animation: spin 1s linear infinite;
  }

.errorContainer {
  min-height: 30px; /* Adjust the height as needed */
  margin-top: 5px;
}

.errorContainer .errorMessage {
  display: block;
}

.placeholder {
  visibility: hidden; /* Hides the element, but keeps its space */
  height: 30px; /* Adjust this height to match the height of your error message */
}