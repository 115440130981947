.gridContainer {
    display: grid;
    grid-template-columns: repeat(3);
    grid-template-rows: repeat(2);
    gap: 10px;
}

.grid1x1 {
    padding-left: 40px;
    padding-right: 40px;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.grid2x1 {
    padding-left: 40px;
    padding-right: 40px;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.grid2x2 {
    padding-left: 40px;
    padding-right: 40px;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}

.ErrorStyle {
    height: 20px;
    color: red;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
}

.ErrorStyle::before {
    content: '\00a0';
    display: inline-block;
    height: 100%;
    visibility: hidden;
  }