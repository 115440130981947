.projectLogo {
    position: relative; 
  }
  
.projectLogo img {
  position: fixed;
  bottom: 0;
  padding-left: 5%;
  padding-bottom: 40px;
  width: 9%; 
}