.myStyleMidList {
  align-items: flex-start;
  /* box-shadow: 0px 24px 48px 0px rgba(0, 0, 0, 0.20), 0px 3px 6px 0px rgba(0, 0, 0, 0.10); */
  background-color: #ff1010;
  align-self: center;
  display: flex;
  width: 100%;
  min-width: 200px;
  max-width: 1500px;
  max-Height: 480px;
  overflow-x: hidden;
  overflow-Y: auto;
  padding-Left: 50px;
  padding-Right: 50px;
  padding-Top: 20px;
  padding-Bottom: 40px;
  flex-Direction: column;
}

.tableStyles {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  margin-top: 20px;
}

.thStyles {
  border-bottom: 2px solid #47455b;
  padding: 12px;
  text-align: center;
  background: #464463;
  color: #ffffff;
}

.tdStyles {
  text-align: center;
  background-color: #3c3d4d;
  padding: 10px;
  color: #ffffff;
}

.tdButtonStyles {
  justify-content: center;
  background-color: #3c3d4d;
  padding: 5px;
  display: flex;
  opacity: 1;
}

.tableDisabled {
  background-color: rgba(81, 76, 76, 0.4);
  color: rgba(255, 255, 255, 0.4);
}
