.tableStyles {
    text-align: center;
    margin-top: 20px;
  }

.tag {
    align-content: center;
    width: 80%;
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
    border-radius: 4px;
    text-align: center;
}

.tagS {
    width: 10%;
    padding: 5px;
    border: none;
    border-radius: 4px;
}

.tagM {
    width: 100%;
    min-width: 50px;
    padding: 5px;
    border: none;
    border-radius: 4px;
}

.tagB {
    color: #ffffff;
    background-color: #3c3d4d;
    width: 95%;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
}

.tagB2 {
    color: #ffffff;
    background-color: #3c3d4d;
    width: 90%;
    padding: 5px;
    border: none;
    border-radius: 4px;
    text-align: center;
}

.tagProjName {
    color: #ffffff;
    background-color: #3c3d4d;
    width: 90%;
    padding: 5px;
    border: none;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}

.tagFactoryName {
  color: #ffffff;
  background-color: #3c3d4d;
  width: 20%;
  padding: 5px;
  border: none;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
}

.tagTextStarter {
  display: flex; 
  align-items: center; 
  width: 96%; 
  padding-left: 10px;
}

.tagText {
    text-align: center;
    background-color: #3c3d4d;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}



.tagList {
    width: 600px; 
    height: 170px; 
    max-height: 170px; 
    overflow-y: auto;
  };

.thStyles {
border-bottom: 2px solid #ddd;
padding: 12px;
background: #f2f2f2;
color: #ffffff;
}


.error-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: red;
    color: white;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    margin-right: 5px;
    cursor: pointer;
    position: relative;
  }
  
  .error-message {
    display: none;
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    color: black;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px;
    z-index: 100;
    text-align: left;
  }
  
  .error-icon:hover .error-message {
    display: block;
  }

  .errorContainerStyles {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 75vh;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  }